import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    LOADING: false,
    params: "",
    imgKey: "",
    imgList: "",
    report: "",
    impairment: "",
    play: "",
    reportNum: "",
    pdfUrl:'',
  },
  mutations: {
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
    setParams(state, data) {
      // console.log(data);
      state.params = data;
    },
    setImgKey(state, data) {
      // console.log(data);
      state.imgKey = data;
    },
    setImg(state, data) {
      // console.log(data);
      state.imgList = data;
    },
    setReport(state, data) {
      // console.log(data);
      state.report = data;
    },
    setImpairment(state, data) {
      // console.log(data);
      state.impairment = data;
    },
    setPlay(state, data) {
      state.play = data;
    },
    set_reportNum(state, data) {
      state.reportNum = data;
    },
    set_pdf(state, data) {
      state.pdfUrl = data;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
