<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "./components/loading.vue";
export default {
  computed: {
    ...mapState(["LOADING"]),
  },
  name: "App",
  components: { Loading },
};
</script>

<style>
#app {
  width: 100%;
  min-height: 100vh;
}
a {
  display: none ;
}
</style>
