import Vue from "vue";
import {
  Button,
  Uploader,
  ImagePreview,
  ActionSheet,
  Toast,
  Icon,
  Popup,
  DatetimePicker,
  Cascader,
  Picker,
  RadioGroup,
  Radio,
  Dialog,
  NumberKeyboard,
  SwipeItem,
  Swipe,
  Tabs,
  Tab,
} from "vant";

// 全局注册
Vue.use(ImagePreview);
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Button);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(NumberKeyboard);
Vue.use(SwipeItem);
Vue.use(Swipe);
Vue.use(Tabs);
